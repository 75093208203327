import { SVGProps, FC } from 'react'
import dynamic from 'next/dynamic'
import { Container, Box, Stack, SimpleGrid, useBreakpointValue } from '@chakra-ui/react'
import { H3Title } from 'src/uikit'
import Carousel from '../common/Carousel'

const Card = dynamic(() => import('./Card'))

interface LocalProps {
  icon: SVGProps<SVGSVGElement>
}

type CardProps = BoxGlobal.WhyCP & LocalProps

interface Props {
  items: Array<CardProps>
  title: string
}

const WhyCarPlannerList: FC<Props> = ({ title, items }) => {
  const isMobile = useBreakpointValue({ base: true, md: false })

  return <Box bg="baseWhite" pb="m" pt="xxl6">
    <Container as={Stack} maxW="8xl" textAlign="center">
      <H3Title>{title}</H3Title>
      {isMobile ?
        <Carousel
          slidesPerView={1}
          withPagination
          spaceBetween={30}
          className="home-why-carousel"
        >
          {items.map(item => {
            return (
              <Carousel.Item key={item.title}>
                <Card
                  title={item.title}
                  description={item.description}
                  icon={item.icon}
                />
              </Carousel.Item>
            )
          })}
        </Carousel>
        :
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 3 }}
          spacing="l"
          textAlign="center"
          paddingBottom="xxl6"
        >
          {items.map(item => (
            <Card
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </SimpleGrid>
      }
    </Container>
  </Box>
}

export default WhyCarPlannerList
